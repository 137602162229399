import styled from "@emotion/styled";
import { CSS_COLOR } from "../assets/styles/common";

export const SkillTableContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  max-width: 864px;
  margin-bottom: 24px;
  -webkit-overflow-scrolling: touch;
  table {
    border-collapse: separate;
    border-spacing: 4px;
    color: ${CSS_COLOR.baseText};
    table-layout: fixed;
    width: 100%;
    &.isGuide {
      width: 50%;
      margin: 0 auto 24px;
      td {
        font-size: 14px;
        padding: 0 4px;
      }
    }
  }
  tr {
    background-color: transparent;
  }
  th,
  td {
    text-align: center;
    width: 100px;
  }
  th {
    background-color: transparent;
    font-size: 12px;
    font-weight: normal;
    padding: 0 4px;
    &.category {
      color: rgb(160, 160, 160);
      font-size: 13px;
      font-weight: bold;
      padding: 24px 0 0;
      text-align: left;
      width: 100px;
    }
  }
  td {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  .level-5 {
    background-color: rgba(158, 202, 38, 1);
  }
  .level-4 {
    background-color: rgba(158, 202, 38, 0.7);
  }
  .level-3 {
    background-color: rgba(158, 202, 38, 0.5);
  }
  .level-2 {
    background-color: rgba(158, 202, 38, 0.3);
  }
  .level-1 {
    background-color: rgba(158, 202, 38, 0.1);
  }
  .level-0 {
    background-color: #f7f7f7;
  }
`;

export const FloatContainer = styled.section`
  align-items: center;
  align-content: center;
  background-color: ${CSS_COLOR.baseBG};
  border: 1px solid #eaeaf2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10vh 0 5vh;
  padding: 1vh 0 10vh;
  position: relative;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 16px;
    letter-spacing: 0.12em;
    width: 100%;
  }
  p {
    letter-spacing: 0.02em;
    margin: 1vh 0;
    white-space: pre-line;
  }
  .mission {
    font-size: 24px;
    font-weight: 100;
    line-height: 1.8;
  }
`;

export const H2Container = styled.div`
  margin: 10vh 0 5vh;
  text-align: center;
  .wf {
    font-size: 82px;
    font-weight: 300;
    line-height: 1;
  }
  h2 {
    font-size: 16px;
    letter-spacing: 0.2em;
  }
  &::after {
    content: "";
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin: 12px auto 0;
    width: 8px;
  }
`;

export const ProjectContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 24px 0 32px 16px;
  position: relative;
  &::before {
    background-color: rgba(220, 220, 220);
    content: "";
    display: inline-block;
    height: 100%;
    left: 21px;
    position: absolute;
    top: 0;
    width: 1px;
    z-index: 0;
  }
  .mark {
    align-items: center;
    background-color: #fff;
    border: 2px solid rgba(220, 220, 220);
    border-radius: 50%;
    display: flex;
    font-size: 2px;
    height: 11px;
    justify-content: center;
    margin-top: 34px;
    position: relative;
    text-indent: 3px;
    vertical-align: -2px;
    width: 11px;
    z-index: 10;
  }
  .info {
    background-color: ${CSS_COLOR.baseBG};
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2),
      -3px -3px 5px rgba(255, 255, 255, 0.6);
    color: ${CSS_COLOR.baseText};
    padding: 12px;
    position: relative;
    width: calc(100% - 16px - 16px);
    &::before {
      background-color: #fff;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-right: none;
      border-bottom: none;
      box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05),
        -1px 0px 1px rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 32px;
      left: -7px;
      transform: rotate(-45deg);
    }
  }
  .title {
    font-size: 20px;
    padding: 0;
  }
  .title_icon {
    display: inline-block;
    margin: 0;
    margin-right: 1.25rem;
    width: 2rem;
  }
  .term {
    display: inline-block;
    font-size: 13px;
    left: 0;
    letter-spacing: 0.08em;
    position: absolute;
    top: -20px;
  }
  .role,
  .team,
  .skill {
    color: ${CSS_COLOR.subText};
    font-size: 12px;
    margin-top: 4px;
    &::before {
      display: inline-block;
      font-size: 11px;
      width: 3.25rem;
    }
  }
  .role {
    color: ${CSS_COLOR.baseText};
    padding: 8px 0 12px;
    &::before {
      content: "ロール:";
    }
  }
  .team {
    &::before {
      content: "メンバー:";
    }
  }
  .skill {
    &::before {
      content: "使用技術:";
    }
  }
`;

export const TopicsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding-bottom: 28px;
  padding-left: 16px;
  position: relative;
  &::before {
    background-color: rgba(220, 220, 220);
    content: "";
    display: inline-block;
    height: 100%;
    left: 21px;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .mark {
    align-items: center;
    background-color: rgba(250, 250, 250);
    display: flex;
    font-size: 16px;
    width: 11px;
    height: 11px;
    justify-content: center;
    border: 1px solid rgba(220, 220, 220);
    border-radius: 50%;
    margin-right: 22px;
    margin-top: 30px;
    position: relative;
    text-indent: 3px;
    vertical-align: -2px;
    z-index: 10;
  }
  .topics {
    background-color: ${CSS_COLOR.baseBG};
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1),
      -2px -2px 4px rgba(255, 255, 255, 0.5);
    color: ${CSS_COLOR.baseText};
    font-size: 13px;
    font-weight: 200;
    margin: 12px 0 0;
    padding: 12px 24px 12px 18px;
    position: relative;
    &::before {
      background-color: #fff;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.04),
        -1px 0px 1px rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 18px;
      left: -7px;
      transform: rotate(-45deg);
    }
    i {
      padding-right: 1rem;
    }
    &_term {
      display: inline-block;
      font-size: 13px;
      left: 0;
      letter-spacing: 0.08em;
      position: absolute;
      top: -20px;
    }
  }
`;

export const EmphasisContainer = styled.dl`
  background-color: ${CSS_COLOR.baseBG};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  margin: 0 auto 16px;
  padding: 16px;
  .dt {
    font-size: 62px;
    line-height: 0.4;
    margin-right: 16px;
    min-width: 120px;
    padding: 24px 0 0;
    text-align: center;
  }
  .unit {
    display: inline-block;
    font-size: 18px;
    padding: 0;
    text-align: center;
    width: 100%;
  }
  .inner_dt {
    font-size: 18px;
    font-weight: 200;
    padding: 24px 0 32px;
  }
  .inner_dd {
    color: #555;
    font-size: 13px;
  }
`;

export const ReasonContainer = styled.div`
  .reasons {
    list-style: none;
  }
  .reason {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 8px;
    padding: 16px 8px;
    text-align: center;
  }
  .note {
    width: 80%;
    margin: 32px auto 0;
    text-align: justify;
    p {
      font-size: 14px;
      font-weight: 200;
    }
  }
`;
