import React from "react";
import styled from "@emotion/styled";
import img from "../assets/img/hexar_000926.jpg";
import { HeartFillIcon } from "@primer/octicons-react";

const TopCover = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  h1 {
    font-size: 42px;
    font-weight: 300;
    margin: 60px 0 10px;
    span {
      font-weight: 400;
      padding: 0 0 0 0.1em;
    }
  }
  .text {
    text-align: center;
    width: 40%;
    .name {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.04em;
    }
    .msg {
      margin-top: 16px;
    }
    .icon {
      color: red;
    }
  }
  .img {
    width: 55%;
  }
`;

export default function Cover() {
  return (
    <TopCover>
      <div className="text wf">
        <h1>
          Under<span>Mountain</span>
        </h1>
        <p>
          <span className="name">Kouichirou Yamashita</span>
          <br />
          Kyoto, Japan
        </p>
        <p className="msg">
          <HeartFillIcon size={16} className="icon" />
          <br />
          Interface, Tech, Update & Web
        </p>
      </div>
      <img src={img} className="img" alt="Web for Life" />
    </TopCover>
  );
}
