import React from "react";

import { ReasonContainer } from "./styles";

export default function ReasonList({ reasons, cmt }) {
  const lists = reasons.map((v) => <li className="reason">{v}</li>);
  const note = cmt ? (
    <div className="note">
      <p>{cmt}</p>
    </div>
  ) : null;
  return (
    <ReasonContainer>
      <ul class="reasons">{lists}</ul>
      {note}
    </ReasonContainer>
  );
}
