import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Cover from "../../../components/Cover";
import SkillTable from "../../../components/SkillTable";
import FloatUnit from "../../../components/FloatUnit";
import SectionTitle from "../../../components/SectionTitle";
import ProjectBox from "../../../components/ProjectBox";
import TopicsBox from "../../../components/TopicsBox";
import EmphasisUnit from "../../../components/EmphasisUnit";
import ReasonList from "../../../components/ReasonList";
import { mission } from "../../../assets/text/mission";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Cover mdxType="Cover" />
    <br />
    <br />
    <FloatUnit id="mission" mdxType="FloatUnit">
  <h2>やりたいこと</h2>
  <p className="mission">{mission}</p>
    </FloatUnit>
    <br />
    <br />
    <SectionTitle id="strengths" english="Strengths" mdxType="SectionTitle">
  できること・得意なこと
    </SectionTitle>
    <EmphasisUnit phrase="+10" phraseUnit="years" lead="Web に関係する 10 年以上の豊富な実務・実装経験" list={["ユーザーが使いやすく、心地よいインターフェースの追求", "成果を意識した Web サイト/ Web App 制作", "スピード感とアジャイルな実装・プロトタイピング", "フワッとしたイメージをコミュニケーションを取りながら形・仕様にしていく能力", "複雑な要求・仕様を、シンプルな設計・ロジックで組み立てる設計能力", "ビジネス視点・ユーザー視点と実装面・ブランディング面を複合的な重要度の判断能力とそれを説明する技術", "400 万 PV/月以上のサイト立ち上げ・運用経験", "UX や高いパフォーマンスを意識したサイト構築・設計・調整技術", "企画 → UI デザイン → 実装（フロントエンド・バックエンド・DB 設計）全工程を実務として経験", "デザイン・エンジニアリング・ビジネス全ての領域への関心とこだわり"]} mdxType="EmphasisUnit" />
    <EmphasisUnit phrase="+5" phraseUnit="years" lead="チームを主体とした開発経験とチームをリードしてきた実績" list={["会社・事業として高いアウトカムの実現と個人のよろこびを意識したチーム開発を大切にしています", "チームで協力することで一人ではなし得ない、大きな成果を実現してきた経験", "お互いが協力して、継続的に、しあわせに働けるような場づくり・情報共有", "チーム開発文化のない組織の中で、チームの立ち上げ・チームビルディング（5〜15人）を行った成功体験", "ステークホルダーや他部署との折衝・説明", "情報共有を活発化させ、質を高める、分報やコードレビューの導入・運用経験", "より強く・速く・円滑に開発するための組織設計・採用活動・教育", "できるだけ早く活躍でき、会社に馴染めるようにするためのオンボーディングを牽引", "より効果的な時間にするための Mtg・振り返りなどのファシリテーション"]} mdxType="EmphasisUnit" />
    <EmphasisUnit phrase="+5" phraseUnit="years" lead="5 年以上のプロダクト/プロジェクト開発経験" list={["本質的な課題解決のために効果的な問題点の定義", "最短かつ最大のアウトカムを出すための課題設定・仕様策定", "社内で高い評価を受けたプロダクト/プロジェクトリーダー経験", "ユーザー目線とブランディング双方を重ね合わせた意思決定とその説明", "プロダクトを成功・定義するミッションの策定", "取り組むべき重要度の高いイシューの選定とその説明", "ユーザーストーリー・ペルソナの設定から必要な機能のリストアップと優先順位付け", "デザインスプリントをはじめとした、より成果を生み出すためのフレームワークの知識と導入経験", "ステークホルダーや他部署・外部との折衝・ファシリテーション"]} mdxType="EmphasisUnit" />
    <EmphasisUnit phrase="+20" phraseUnit="years" lead="かれこれ 20 年以上の Web 好き" list={["アップデート・新しい可能性を求めることが好き", "アップデートを止めない・可能性に満ちた Web 大好き", "未来のコンピューティングやスマホの次のデバイス・方向性に興味がある", "まだ正解がよくわからない未知のことへ取組み、新しい可能性を掘っていく事がよろこび", "ドメイン好き", "ガジェット好き"]} mdxType="EmphasisUnit" />
    <br />
    <br />
    <SectionTitle id="why" english="Why" mdxType="SectionTitle">
  私が転職を検討している理由
    </SectionTitle>
    <ReasonList reasons={["人や世界のアップデートに貢献できる「仕事」がしたい", "変化を求め、スピード感を持って取り組めるチームで、より大きな成果を狙いたい", "働く場所・働き方を含め、新しい可能性を追求したい", "心地よく・しあわせなインターフェースを追求したい", "チーム・メンバー・従業員を大切に考えてくれる組織に所属したい"]} cmt="現職の中で、自分自身が情熱を持って取り組めそうなものを見出し、提案の上、取り組んで行けそうな可能性はある。  
スピード感や組織の体勢・文化に関しては課題感を感じており、別の可能性・場所でハードワークする可能性も検討してみたい。" mdxType="ReasonList" />
    <SectionTitle id="skill-map" english="Skill Map" mdxType="SectionTitle">
  スキルマップ
    </SectionTitle>
    <SkillTable isGuide={true} skill={[["エース", 5], ["得意", 4], ["ひとりでできる", 3], ["助けがあればできる", 2], ["入門中", 1], ["やったことがない", 0]]} mdxType="SkillTable" />
    <SkillTable skill={[["リーダーシップ", 5], ["チーム開発", 4], ["マネジメント", 3], ["要件定義", 4], ["設計", 3], ["実装", 3], ["運用", 3], ["情報収集", 5]]} mdxType="SkillTable" />
    <SkillTable skillCategory="実装" skill={[["Design", 3], ["HTML", 5], ["CSS", 5], ["JavaScript", 4], ["PHP", 3], ["Ruby", 1], ["Python", 2], ["Java", 0], [".NET", 0], ["C#", 2], ["Swift", 1], ["DB", 2], ["Git", 3], ["CI/CD", 1], ["テスト", 2], ["チーム開発", 4], ["コードレビュー", 4]]} mdxType="SkillTable" />
    <SkillTable skillCategory="デザイン" skill={[["Graphic", 2], ["UI", 4], ["UX", 2], ["ユーザーインタビュー", 2], ["ユーザーストーリー", 3], ["プロトタイプ", 4]]} mdxType="SkillTable" />
    <SkillTable skillCategory="Frontend" skill={[["HTML", 5], ["WAI-ARIA", 3], ["CSS/Sass", 5], ["JavaScript", 4], ["ES2015~", 3], ["Node.js", 3], ["TypeScript", 1], ["React", 3], ["Vue", 4], ["Angular", 1], ["Svelte", 3], ["Puppeteer", 3], ["Electron", 2], ["jQuery", 4], ["PWA", 3], ["Performance", 5], ["Test", 2], ["環境構築", 3], ["JAMStack", 4]]} mdxType="SkillTable" />
    <SkillTable skillCategory="Backend" skill={[["PHP", 3], ["Laravel", 2], ["Python", 2], ["Java", 1], [".NET", 0], ["C#", 2], ["REST API", 3], ["GraphQL", 2], ["MySQL", 3], ["NoSQL", 2]]} mdxType="SkillTable" />
    <SkillTable skillCategory="App" skill={[["VS Code", 4], ["Photoshop", 4], ["Illustrator", 3], ["Sketch", 2], ["XD", 3], ["Figma", 2], ["XCode", 2]]} mdxType="SkillTable" />
    <SkillTable skillCategory="Services" skill={[["GitLab", 4], ["GitHub", 3], ["Google Analytics", 4], ["Slack", 3], ["Shopify", 3], ["Netlify", 3], ["Firebase", 2], ["Square API", 3], ["Wordpress", 4], ["Notion", 3], ["Trello", 3], ["@LINE", 2]]} mdxType="SkillTable" />
    <br />
    <br />
    <SectionTitle id="career-activities" english="Career &amp; Activities" mdxType="SectionTitle">
  関わった主なプロジェクトとイベント・経歴
    </SectionTitle>
    <ProjectBox title="結婚式場向け 媒体横断 登録ツールの開発" type="project" term="2019.9 ~ " role="プロジェクトリーダー / フロントエンド チームリーダー" skill="Vue/Vuex, Storybook, Sass, Laravel, AWS" team="フロントエンド・エンジニア×4, API・エンジニア×3, バックエンド・エンジニア×3" isHighlight={true} mdxType="ProjectBox" />
    <TopicsBox topics="Oura Ring を購入 - 日々の体調管理・睡眠のトラッキング" term="2020.6" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="Scrum Fest Osaka 2020 参加" term="2020.6" type="zap" mdxType="TopicsBox" />
    <TopicsBox topics="WWDC2020 &amp; iOS14 Beta のインストール" term="2020.6" type="beaker" mdxType="TopicsBox" />
    <ProjectBox title="海外市場向けの EC サイトの開発フォロー" type="code" term="2019.4 ~ " role="外注制作管理・仕様検討" skill="Shopify" team="エンジニア×2, デザイナー×1, 、企画担当×4" mdxType="ProjectBox" />
    <TopicsBox topics="コロナの影響による、リモートワーク導入を牽引" term="2020.3 ~ 6" type="check-circle-fill" mdxType="TopicsBox" />
    <TopicsBox topics="Design Sprint を読み、部分的に業務にも取り入れる" term="2019.12" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="INSPIRED を読み プロダクト・マネージャー が目標の一つになる" term="2019.11" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="組織変更 /　経営企画室 IT/デジタル戦略グループ Web チーム 係長" term="2019.10" type="organization" mdxType="TopicsBox" />
    <ProjectBox title="店舗用 iPad 接客・バックエンドツール" type="code" term="2019.8 ~ 2019.9" role="フロントエンドエンジニア・UI デザイン" skill="Vue, Sass, PWA, iPad" team="エンジニア×1, 企画担当×2" mdxType="ProjectBox" />
    <ProjectBox title="Bu22" type="code" term="2019.5 ~ " role="フロントエンドエンジニア・UI デザイン" skill="Vue, Sass, PWA, Firebase" team="エンジニア×1（個人開発）" mdxType="ProjectBox" />
    <TopicsBox topics="v-kansai Vue.js/Nuxt.js meetup #5 LT登壇" term="2019.5" type="rocket" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="DevLove関西に参加するようになる" term="2019.2 ~" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="Developer Summit 2019 に参加" term="2019.2" type="zap" mdxType="TopicsBox" />
    <ProjectBox title="ブランド Web サイト（PC/Mobile） 新コンテンツ追加" type="code" term="2019.9 ~ 2019.10" role="エンジニアリーダー・レビュワー" skill="PHP, HTML, jQuery, Sass" team="エンジニア×3, デザイナー×2, 企画・構成×1" mdxType="ProjectBox" />
    <ProjectBox title="ブランド Web サイト（PC/Mobile） リニューアル #2" type="code" term="2018.12 ~ 2019.1" role="プロジェクトリーダー / フロントエンド/バックエンドエンジニア" skill="PHP, HTML, jQuery, Sass" team="エンジニア×3, デザイナー×2, 企画・構成×1" isHighlight={true} mdxType="ProjectBox" />
    <TopicsBox topics="iPad Pro を購入 - PC の次の世代のコンピューティング" term="2018.11" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="社内の分業化 体勢の方針が強くなる" term="2018.11 ~" type="issue-opened" mdxType="TopicsBox" />
    <TopicsBox topics="メンバーの 1on1 を開始" term="2018.10 ~ " type="check-circle-fill" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="Apple Watch Series.4 を購入 - ウェアラブル・スマホの次の可能性" term="2018.9" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="iPhone + Smart Keyboard で会社帰りに日々の振り返りを行う" term="2018.8 ~ 11" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="コーチングの研修を受講" term="2018.8" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="振り返り・カンバンによるタスク管理・朝会の導入" term="2018.8 ~ " type="check-circle-fill" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="SubVersion から Git への移行と GitLab の導入・コードレビューの開始" term="2018.6 ~ " type="check-circle-fill" mdxType="TopicsBox" />
    <TopicsBox topics="Oculus Go を購入 - VR の可能性や未来のコンピューティング" term="2018.5" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="本格的にチームビルディングを開始（朝会・共有会の習慣化）" term="2018.4 ~ " type="rocket" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="ステークホルダーへのプロジェクト・チームの報告会を開始（週一回）" term="2018.4 ~ " type="check-circle-fill" mdxType="TopicsBox" />
    <ProjectBox title="サーバー移行・SSL化" type="code" term="2018.4 ~ 9" role="プロジェクトリーダー / フロントエンドエンジニア・バックエンドエンジニア" skill="PHP, MySQL" team="エンジニア×4" mdxType="ProjectBox" />
    <ProjectBox title="結婚情報サイト 式場コンテンツ リニューアル" type="code" term="2018.4 ~ 7" role="プロジェクトリーダー / フロントエンド/バックエンドエンジニア・エンジニアリーダー" skill="PHP, HTML, jQuery, Vue, Sass" team="エンジニア×4, デザイナー×1, 企画・構成×1" mdxType="ProjectBox" />
    <ProjectBox title="チームマネジメント・プロジェクト管理" type="code" term="2018.4 ~ " role="チームリーダー・マネジメント" skill="Vue, Sass, PWA, iPad" team="エンジニア×6" mdxType="ProjectBox" />
    <TopicsBox topics="メディア開発課 Web チーム 係長" term="2018.4 ~ " type="organization" mdxType="TopicsBox" />
    <ProjectBox title="式場管理画面 代行入力用CMS" type="code" term="2018.1 ~ 2018.2" role="エンジニアリーダー / フロントエンドエンジニア・バックエンドエンジニア" skill="PHP, React/Angular, HTML, Sass" team="エンジニア×3, 企画担当×1" mdxType="ProjectBox" />
    <TopicsBox topics="Kyoto.js 14 登壇 「Gatsby Super Fast」" term="2018.3" type="rocket" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="@kakakakakkuさんにブログメンターをしてもらう" term="2018.3 ~ 4" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="ダイアモンドサイト 外部 API 連携" type="code" term="2017.12 ~ 2018.1" role="仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×2, 企画・構成×2, 営業×1" mdxType="ProjectBox" />
    <ProjectBox title="着用情報サイト 追加開発" type="code" term="2017.1 ~ 2" role="フロントエンドエンジニア・バックエンドエンジニア" skill="PHP, Worpress, jQuery, HTML, Sass" team="エンジニア×2, デザイナー×1" mdxType="ProjectBox" />
    <TopicsBox topics="チームメンバーのオンボーディングに注力し始める" term="2017.3 ~ " type="check-circle-fill" mdxType="TopicsBox" />
    <TopicsBox topics="AirPods を購入" term="2016.12" type="beaker" mdxType="TopicsBox" />
    <TopicsBox topics="第一子誕生" term="2016.11" type="squirrel" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="店舗スタッフ用の資料閲覧ツール" type="code" term="2016.7 ~ 8" role="フロントエンドエンジニア・ UI デザイン" skill="PHP, JavaScript/jQuery/p5.js, HTML, Sass" team="エンジニア×1, 企画担当×2" mdxType="ProjectBox" />
    <ProjectBox title="店頭用 会員案内ツール" type="code" term="2016.4" role="フロントエンドエンジニア・ UI デザイン" skill="PHP, JavaScript/jQuery/p5.js, HTML, Sass" team="エンジニア×1, 企画担当×2" mdxType="ProjectBox" />
    <ProjectBox title="式場紹介ページのリニューアル　掲載コンテンツの追加" type="code" term="2016.6 ~ 8" role="エンジニアリーダー / フロントエンドエンジニア・バックエンドエンジニア" skill="PHP, JavaScript/jQuery/p5.js, HTML, Sass" team="エンジニア×3, デザイナー×1, 企画担当×2" mdxType="ProjectBox" />
    <TopicsBox topics="社内・チーム内 勉強会・共有会の主催" term="2016.7 ~ " type="check-circle-fill" mdxType="TopicsBox" />
    <TopicsBox topics="Omoiyari.fm を聴き始める → マネジメントロールの可能性と魅力を知る" term="2016.2" type="zap" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="式場 フェア情報入力 CMS" type="code" term="2016.1 ~ 3" role="エンジニアリーダー / フロントエンドエンジニア・バックエンドエンジニア・UI デザイン" skill="PHP, Angular" team="エンジニア×3, 企画担当×2" mdxType="ProjectBox" />
    <ProjectBox title="式場 Web会員予約機能" type="code" term="2015.12" role="フロントエンドエンジニア・バックエンドエンジニア" skill="PHP, JavaScript/jQuery/p5.js, HTML, Sass" team="エンジニア×3, デザイナー×1, 企画担当×2" mdxType="ProjectBox" />
    <TopicsBox topics="会社内で協力して、楽しく働けないものか思い悩む" term="2015.11 ~" type="issue-opened" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="会員システム・式場予約システム 立ち上げ・実装" type="code" term="2015.10 ~ 12" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass, Synergy" team="エンジニア×1, 企画・構成×1" mdxType="ProjectBox" />
    <ProjectBox title="海外向けブランドサイト制作" type="code" term="2015.7 ~ 8" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1, デザイナー×1, 企画・構成×2, 営業×3" mdxType="ProjectBox" />
    <TopicsBox topics="社長賞・優秀社員賞 受賞" term="2015.6" type="star-fill" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="結婚式場 管理画面 構築" type="code" term="2014.9 ~ 11" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1, デザイナー×1, 企画・構成×2, 営業×3" mdxType="ProjectBox" />
    <TopicsBox topics="チームメンバーが増員していく 2人 → 4人 → 5人" term="2014.10 ~ " type="milestone" isHighlight={true} mdxType="TopicsBox" />
    <TopicsBox topics="PodCast を聴き始める ... 広い世界へのアンテナを貼り始める" term="2014.9 ~" type="zap" mdxType="TopicsBox" />
    <TopicsBox topics="急速なモバイル・シフトを背景に先行した取り組みがブランディングに寄与" term="2014.4 ~" type="star-fill" isHighlight={true} mdxType="TopicsBox" />
    <ProjectBox title="Mobile サイト リニューアル #2" type="code" term="2014.4 ~ 6" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1" mdxType="ProjectBox" />
    <ProjectBox title="結婚情報サイト・結婚式場 紹介サイト 立ち上げ" type="code" term="2014.2 ~ 8" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1, デザイナー×1, 企画・構成×2, 営業×3" mdxType="ProjectBox" />
    <TopicsBox topics="猫 ぽんちょ を飼う" term="2013.12 ~ " type="squirrel" mdxType="TopicsBox" />
    <ProjectBox title="PC サイトを Flash サイトからリニューアル" type="code" term="2012.12 ~ 2013.7" role="フロントエンド/バックエンドエンジニア" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1, デザイナー×1, レタッチャー×1" isHighlight={true} mdxType="ProjectBox" />
    <ProjectBox title="SEO 対策ページを量産" type="code" term="2012.6 ~ 12" role="フロントエンド/バックエンドエンジニア" skill="PHP, HTML, jQuery" team="エンジニア×1, デザイナー×1" mdxType="ProjectBox" />
    <ProjectBox title="スマートフォン向け Mobile サイト 立ち上げ" type="code" term="2012.11 ~ 12" role="フロントエンド/バックエンドエンジニア・UIデザイン・仕様策定・部署間 折衝" skill="PHP, HTML, jQuery, Sass" team="エンジニア×1" isHighlight={true} mdxType="ProjectBox" />
    <TopicsBox topics="採用面接に参加" term="2012.9 ~" type="milestone" mdxType="TopicsBox" />
    <ProjectBox title="株式会社 俄" type="organization" term="2012.3 ~" role="Webデザイナー・フロントエンドエンジニア" skill="PHP, JS, HTML, CSS, MySQL" team="" mdxType="ProjectBox" />
    <ProjectBox title="株式会社 トラット（Tratto Brain）" type="organization" term="2008.11 ~ 2012.1" role="Webデザイナー・リーダー" skill="PHP, HTML, CSS, MySQL" team="エンジニア×6, 営業×3" mdxType="ProjectBox" />
    <TopicsBox topics="写真ブログ kyotokoto を始める" term="2010.4 ~ " type="beaker" mdxType="TopicsBox" />
    <ProjectBox title="明和グラビア株式会社" type="organization" term="2006.4 ~ 2008.8" role="デザイナー・商品企画" skill="Photoshop, Illustrator, Shade" team="エンジニア×6, 営業×3" mdxType="ProjectBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      