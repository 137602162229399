import React from "react";

import Octicon from "./Octicon";

import { TopicsContainer } from "./styles";

export default function TopicsBox({ type, topics, term, isHighlight }) {
  return (
    <TopicsContainer>
      <p className="mark"></p>
      <dl className="topics">
        <dt className="topics_main">
          <Octicon
            type={type}
            size={16}
            className="topics_icon"
            isHighlight={isHighlight}
          />
          {topics}
        </dt>
        {term ? <dd className="topics_term wf">{term}</dd> : null}
      </dl>
    </TopicsContainer>
  );
}
