import React from "react";

import { SkillTableContainer } from "./styles";

export default function SkillTable({ skill, skillCategory, isGuide }) {
  const title = skillCategory ? (
    <th className="category" rowspan="2">
      {skillCategory} →
    </th>
  ) : null;
  const c = isGuide ? ["isGuide"] : [];
  const skillLabels = skill.map((v) => <th>{v[0]}</th>);
  const skillScores = skill.map((v) => {
    return <td className={`level-${v[1]}`}>{v[1]}</td>;
  });
  return (
    <SkillTableContainer>
      <table className={c.join(" ")}>
        <tr>
          {title}
          {skillLabels}
        </tr>
        <tr>{skillScores}</tr>
      </table>
    </SkillTableContainer>
  );
}
