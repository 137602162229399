import React from "react";

import { H2Container } from "./styles";

export default function SectionTitle({ english, id, children }) {
  return (
    <H2Container id={id}>
      <p className="wf">{english}</p>
      <h2>{children}</h2>
    </H2Container>
  );
}
