import React from "react";

import Octicon from "./Octicon";

import { ProjectContainer } from "./styles";

export default function ProjectBox({
  type,
  title,
  term,
  role,
  team,
  skill,
  isHighlight,
}) {
  return (
    <ProjectContainer>
      <p className="mark"></p>
      <dl className="info">
        <dt className="title">
          <Octicon type={type} size={26} isHighlight={isHighlight} />
          {title}
        </dt>
        <dd className="term wf">{term}</dd>
        <dd className="role">{role}</dd>
        <dd className="team">{team}</dd>
        <dd className="skill">{skill}</dd>
      </dl>
    </ProjectContainer>
  );
}
