import React from "react";
import {
  ArchiveIcon,
  BeakerIcon,
  BroadcastIcon,
  CheckCircleFillIcon,
  CodeSquareIcon,
  CodeIcon,
  IssueOpenedIcon,
  GiftIcon,
  MegaphoneIcon,
  MilestoneIcon,
  OrganizationIcon,
  RocketIcon,
  StarFillIcon,
  SquirrelIcon,
  ZapIcon,
} from "@primer/octicons-react";
import { CSS_COLOR } from "../assets/styles/common";
import styled from "@emotion/styled";

const HighlightContainer = styled.i`
  color: ${CSS_COLOR.primary};
`;
const Container = styled.i`
  color: ${CSS_COLOR.subText};
`;

export default function Octicon({ type, size, isHighlight }) {
  const iconAttr = {
    className: "title_icon",
    size,
    verticalAlign: "middle",
  };
  let icon = <CodeIcon {...iconAttr} />;
  switch (type) {
    case "archive":
      icon = <ArchiveIcon {...iconAttr} />;
      break;

    case "beaker":
      icon = <BeakerIcon {...iconAttr} />;
      break;

    case "broadcast":
      icon = <BroadcastIcon {...iconAttr} />;
      break;

    case "check-circle-fill":
      icon = <CheckCircleFillIcon {...iconAttr} />;
      break;

    case "gift":
      icon = <GiftIcon {...iconAttr} />;
      break;

    case "organization":
      icon = <OrganizationIcon {...iconAttr} />;
      break;

    case "issue-opened":
      icon = <IssueOpenedIcon {...iconAttr} />;
      break;

    case "megaphone":
      icon = <MegaphoneIcon {...iconAttr} />;
      break;

    case "milestone":
      icon = <MilestoneIcon {...iconAttr} />;
      break;

    case "rocket":
      icon = <RocketIcon {...iconAttr} />;
      break;

    case "star-fill":
      icon = <StarFillIcon {...iconAttr} />;
      break;

    case "squirrel":
      icon = <SquirrelIcon {...iconAttr} />;
      break;

    case "zap":
      icon = <ZapIcon {...iconAttr} />;
      break;

    default:
    case "project":
      icon = <CodeSquareIcon {...iconAttr} />;
      break;
  }
  return isHighlight ? (
    <HighlightContainer>{icon}</HighlightContainer>
  ) : (
    <Container>{icon}</Container>
  );
}
