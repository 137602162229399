import React from "react";
import styled from "@emotion/styled";

import { EmphasisContainer } from "./styles";

export default function EmphasisUnit({ phrase, phraseUnit, lead, list }) {
  const lists = list.map((v) => {
    return <li className="">{v}</li>;
  });
  return (
    <EmphasisContainer>
      <dt className="dt wf">
        {phrase}
        <span className="unit">{phraseUnit}</span>
      </dt>
      <dd className="dd">
        <dl className="inner">
          <dt className="inner_dt">{lead}</dt>
          <dd className="inner_dd">
            <ul className="inner_list">{lists}</ul>
          </dd>
        </dl>
      </dd>
    </EmphasisContainer>
  );
}
